.commonTabsSec {
    .TabLink {
        .top h3 {
            font-size: 18px;
        }    
    }
    .top h3 {
        font-size: 24px;
    }
    .TabsWrp {
        max-height: calc(100vh - 110px);
        overflow-y:  scroll;
    }
    .tab-content{
        height: 100vh;
        overflow-y: scroll;
    }
    .nav-link {
        
         svg {
            height: 24px;
            width: 24px;
        }
    } 
    .nav-link.active {
        color: #f4694b !important;
        .stroke {
            svg path {
                stroke: #f4694b !important;
                fill: unset !important;
               }    
        }
        svg path {
         fill: #f4694b !important;
        }
    }
}
@media (max-width: 992px){
    .commonTabsSec {
        .tab-content{
            height: auto;
        }
       .TabLink.border-end{
        border-right: 0 !important;
        border-bottom: 1px solid #ddd;

       }

    }
}