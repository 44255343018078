.DocsForms{
    .FormQuestion {
        .CardBody {
            ul .left {
                width: 120px;
                flex-shrink: 0;
            }

            .single-question-outer:not(:last-of-type) {
                border-bottom: 1px solid var(--bs-border-color);
            }
        }
    }
}