@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@font-face {
  font-family: 'ABeeZee';
  src: url('../fonts/ABeeZee/ABeeZee-Regular.eot');
  src: url('../fonts/ABeeZee/ABeeZee-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ABeeZee/ABeeZee-Regular.woff2') format('woff2'),
      url('../fonts/ABeeZee/ABeeZee-Regular.woff') format('woff'),
      url('../fonts/ABeeZee/ABeeZee-Regular.ttf') format('truetype'),
      url('../fonts/ABeeZee/ABeeZee-Regular.svg#ABeeZee-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('../fonts/Cerebri/CerebriSans-Bold.eot');
  src: url('../fonts/Cerebri/CerebriSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cerebri/CerebriSans-Bold.woff2') format('woff2'),
      url('../fonts/Cerebri/CerebriSans-Bold.woff') format('woff'),
      url('../fonts/Cerebri/CerebriSans-Bold.ttf') format('truetype'),
      url('../fonts/Cerebri/CerebriSans-Bold.svg#CerebriSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kelvinch';
  src: url('../fonts/Kelvinch/Kelvinch-Bold.eot');
  src: url('../fonts/Kelvinch/Kelvinch-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Kelvinch/Kelvinch-Bold.woff2') format('woff2'),
      url('../fonts/Kelvinch/Kelvinch-Bold.woff') format('woff'),
      url('../fonts/Kelvinch/Kelvinch-Bold.ttf') format('truetype'),
      url('../fonts/Kelvinch/Kelvinch-Bold.svg#Kelvinch-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/segoe-ui/SegoeUI.eot');
  src: url('../fonts/segoe-ui/SegoeUI.eot?#iefix') format('embedded-opentype'),
      url('../fonts/segoe-ui/SegoeUI.woff2') format('woff2'),
      url('../fonts/segoe-ui/SegoeUI.woff') format('woff'),
      url('../fonts/segoe-ui/SegoeUI.ttf') format('truetype'),
      url('../fonts/segoe-ui/SegoeUI.svg#SegoeUI') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

