div#root {
  .sidebar.GuideSidebar {
    width: 220px !important;
   
  }
  main.guideMain {
    width: calc(100% - 220px);
    .container-fluid {
      padding: 0 15px !important;
    }
  }
}
