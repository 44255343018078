section.AuthLayout {
  height: 100vh;
  font-size: 14px;
  overflow: hidden;
  .imgWrpper {
    top: 0;
    left: 0;
    z-index: -1;
  }
  .FormInner::-webkit-scrollbar {
    display: none;
  }
  .FormInner {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 20px;
    .OtpCstm input {
      height: 55px;
      width: 55px;
      border-radius: 5px;
      border: 1px solid #ddd;
      outline: 0 !important;
    }
    .top {
      h2 {
        font-size: 30px;
        color: #323232;
      }
    }
  }
}
@media (max-width: 992px) {
  section.AuthLayout {
    .imgWrpper {
      width: 100%;
    }
  }
}
@media (max-width: 480px) {
  section.AuthLayout .FormInner {
    padding: 25px 15px !important;
    border-radius: 10px;
    .top {
      text-align: center;
      h2 {
        font-size: 22px;
      }
    }
  }
}
