@import url(./Variable.scss);
@import url(./Font.scss);
@import url(./Table.scss);

/**::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}*/

/* Track */
/**::-webkit-scrollbar-track {
  background: #ddd;
}*/

/* Handle */
/**::-webkit-scrollbar-thumb {
  background: #08415c;
}*/

body {
  font-family: "Segoe UI" !important;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-bodyColor);
  /* word-break: break-word; */
}

.text-white {
  color: #fefae0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI" !important;
  /* font-family: "Kelvinch"; */
  color: #1a202c;
}

a,
.themeLink {
  text-decoration: none;
  color: var(--bs-hyperLinkColor) !important;
}

.formText {
  font-size: 12px;
}

a:hover {
  /* color: var(--bs-buttonHover); */
  transition: 0.4s;
}

.gap-10 {
  gap: 10px !important;
  -webkit-gap: 10px !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.fw-sbold {
  font-weight: 600 !important;
}

.modal-content {
  border-radius: 10px;
  border: 0;
}

.dropdown-toggle:after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.underline {
  text-decoration: underline !important;
}

.SuccessText {
  color: #05cd99 !important;
}

.DangerText {
  color: #ee5d50 !important;
}

.WarningText {
  color: #ffce20 !important;
}

textarea.resizable {
  resize: vertical !important;
  min-height: 100px;
  max-height: 500px;
}

.tooltip-inner {
  background-color: #fefae0 !important;
  padding: 8px 15px;
  color: black !important;
  font-weight: 500;
}

.tooltip .tooltip-arrow {
  display: none;
}

.CstmLabel {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 500;
}

.GreenLabel.CstmLabel {
  background: #548b54;
  border-color: #548b54;
}

.commonBtnTabs .nav-link {
  color: #323232;
}

.commonBtnTabs .nav-link.active {
  background: #f4694b;
  color: #fff !important;
}

.FormbtnWrpper .btn {
  height: 45px;
  font-size: 12px;
  min-width: 120px;
}

.form-label {
  color: #4a5568;
  font-size: 14px;
}

.form-control {
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  color: #2d3748;
  box-shadow: unset !important;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.form-control:focus {
  border-color: var(--bs-button);
}

.form-check {
  accent-color: var(--bs-button);
}

.d-flex>.imgWrp {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.theme-clr {
  color: #2b3674 !important;
}

.theme-blu {
  color: #2d3748 !important;
}

.theme-clr2 {
  color: #f4694b !important;
}

.rightMenu.dropdown .dropdown-menu {
  left: unset !important;
  right: 0 !important;
}

.dropdown-menu {
  border-radius: 10px;
  border: 1px solid #b6b5b7;
}

.dropdown-menu a {
  font-size: 12px;
}

.noLastBorder>*:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.commonBtn {
  height: 50px;
  background: var(--bs-button);
  border-color: var(--bs-button);
  white-space: nowrap;
}

.commonBtn svg path {
  fill: #fff;
}

.theme2BorderedBtn {
  border-radius: 5px;
  border: 1px solid #f4694b;
  background: #fff0db;
  color: #f4694b;
}

.commonBtn:hover {
  background: var(--bs-buttonHover) !important;
  border-color: var(--bs-buttonHover) !important;
}

.secondaryBtn {
  height: 50px;
  background: #f4694b !important;
  border-color: #f4694b !important;
  white-space: nowrap;
}

.secondaryBtn:hover {
  background: #fe7c5e !important;
  border-color: #fe7c5e !important;
}

.theme2BorderedBtn:hover span.icn svg path {
  fill: #fff;
}

html div#root .dropdown-item.active,
html body div#root .dropdown-item:active {
  background: #000;
  color: #fff !important;
}

.dropdown-toggle.noAfter:after {
  display: none;
}

header.siteHeader .pagetitle {
  color: #323232;
  font-size: 22px;
}

.commonContent h4 {
  font-size: 18px;
}

.commonContent h3 {
  font-size: 20px;
}

.commonContent h2 {
  font-size: 26px;
  line-height: 32px;
}

.commonContent p {
  font-size: 14px;
  line-height: 24px;
}

div.SectionHeader .pagetitle {
  color: #323232;
  font-size: 18px;
}

.colorCards .cardCstm {
  border-radius: 10px;
}

.colorCards .cardCstm .imgWrp {
  height: 50px;
  width: 50px;
}

.gettingStarted {
  border-radius: 10px;
  border: 1px solid #405c40;
}

.gettingStarted .BulletList a {
  color: #323232;
  font-size: 12px;
}

.gettingStarted .BulletList a:hover span.icn svg path {
  fill: #405c40;
}

.gettingStarted .BulletList a:hover {
  text-decoration: underline;
  color: #405c40;
}

.commonCardBox {
  border-radius: 10px;
  border: 1px solid #ececec;
}

.commonCardBox .top h3 {
  font-size: 17px;
}

.commonCardBox .top h2 {
  font-size: 20px;
}

.btnSm span.icn svg {
  height: 14px;
  width: 14px;
}

.btnSm {
  font-size: 12px;
  height: 40px;
  padding: 0 10px;
}

.listView .imgWrp svg {
  height: 60%;
}

.listView .imgWrp {
  aspect-ratio: 1/1;
}

.listView .content h6 {
  font-size: 16px;
}

.listView .content p {
  font-size: 13px;
}

.listView .content span.tym {
  font-size: 11px;
}

.listView .LinkCard:hover {
  background: #fefae0;
  border-color: #fefae0 !important;
}

.cstmInputCheckWrp {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  top: 2px;
}

.cstmInputCheck {
  border-radius: 2px;
  border: 1px solid #bababa;
  background: #e8e8e8;
}

.cstmInputCheckWrp input.position-absolute {
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.cstmInputCheckWrp input.position-absolute:checked+.cstmInputCheck {
  border-color: #f4694b;
  background-color: #ffebd0;
}

.cstmInputCheckWrp input.position-absolute:checked+.cstmInputCheck span.icn {
  display: flex !important;
}

.cstmInputCheckWrp.radio .cstmInputCheck {
  background: #dadada !important;
  border-color: #dadada !important;
  padding: 3px;
}

.cstmInputCheckWrp.radio .cstmInputCheck span.icn {
  height: 100%;
  width: 100%;
  background: #f4694b;
}

.iconWithText .icn.position-absolute {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.iconWithText input.form-control {
  padding-left: 34px;
}

.FilterWrp .iconWithText input,
.FilterWrp .cstmCard {
  border-radius: 10px;
}

.commonCardBox .top button {
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
  padding: 3px 8px;
}

.denotionList {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
}

.denotionList .denotion {
  width: 29px;
  height: 13px;
}

.denotionList p {
  font-size: 13px;
  color: #2d3748;
}

.SubCardBox {
  border-radius: 15px;
}

.SubCardBox .innerCard {
  border-radius: 10px;
}

.PricingCard .priceHead {
  font-size: 35px;
}

.PricingCard .priceHead span {
  font-size: 25px;
}

.PricingCard .commonBtn:hover {
  background: #f4694b !important;
  border-color: #f4694b !important;
}

.PricingCard {
  color: #1a202c;
}

.FormPop .modal-header .h4 {
  font-size: 16px;
  color: #323232;
  /* font-family: "kelvinch"; */
  font-family: "Segoe UI" !important;
}

.FormPop .modal-dialog {
  max-width: 582px;
}

section.paymentSubs {
  min-height: 100vh;
}

section.paymentSubs .formWrpper {
  max-width: 450px;
  margin: 0 auto;
}

section.paymentSubs:after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 50%;
  right: 0;
  background: #f8f8f8;
  z-index: -1;
}

.colorCards .cardCstm h3 {
  font-size: 18px;
  height: 42px;
}

.ColoredAcorrdion.accordion .accordion-button {
  background: transparent;
  border: 0 !important;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  text-transform: uppercase;
  font-family: "Segoe UI" !important;
  flex-wrap: wrap;
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button span.toggleBtn {
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  border-radius: 5px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button.collapsed span.toggleBtn:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  height: 80%;
  width: 2px;
  background: #000;
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button span.toggleBtn:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 80%;
  height: 2px;
  background: #000;
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button {
  padding-right: 60px;
}

.ColoredAcorrdion.accordion .accordion-header,
.ColoredAcorrdion.accordion .accordion-item {
  border-radius: 15px;
}

.ColoredAcorrdion.accordion .accordion-button:after {
  display: none;
}

.accordion .accordion-button {
  font-size: 12px;
}

.uploadFile {
  border-radius: 10px;
  border: 1px dashed #bbb;
}

.uploadFile input {
  left: 0;
  top: 0;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}

.noteCstm.alert {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.noteCstm.Succes {
  background: #ddffda;
  border: 1px solid #008e1f;
  color: #008e1f;
  border-radius: 6px;
}

.error-message-doc {
  color: #ad2525;
  font-weight: 600;
  text-align: center;
}

li.tags_list {
  cursor: pointer;
}

.ProgressBarBox {
  top: 90px !important;
  z-index: 1019;
}

.ProgressBarBox li.active * {
  color: #f4694b !important;
}

.table-responsive {
  border-bottom: 1px solid #ddd;
}

ul.pagination .page-link {
  font-size: 12px;
  border: 0;
  background: transparent;
  color: #08415c !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.pagination {
  background: #e5e5e5;
  display: inline-flex;
  border-radius: 30px;
}

ul.pagination li.page-item {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

ul.pagination li.page-item.active {
  background: #08415c;
  color: #fff !important;
}

ul.pagination li.page-item.active .page-link {
  color: #fff !important;
}

.CstmScale .cstmBox {
  min-width: 55px;
  height: 24px;
}

.CstmScale .cstmBox p {
  opacity: 0;
}

.CstmScale input {
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.CstmScale input:checked+div.cstmBox {
  border: 2px solid #000;
}

.CstmScale input:checked+div.cstmBox p {
  opacity: 1;
}

.noAfter.dropdown .dropdown-toggle:after {
  display: none;
}

.clientFeature button.accordion-button {
  box-shadow: unset !important;
  background: transparent;
  color: #000;
  font-family: "Segoe UI" !important;
  font-weight: 700;
  height: auto;
  padding: 5px 0;
}

.clientFeature .accordion-collapse {
  background: #f1f1f1;
}

.clientFeature button.accordion-button:after {
  position: absolute;
  content: "";
  right: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  height: 8px;
  width: 8px;
  background: transparent;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}

.clientFeature button.accordion-button.collapsed:after {
  transform: translateY(-50%) rotate(135deg);
}

p.invalid-feedback_ {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

p.m-0.formText.errorMessage {
  color: #e33636;
}

section.HelpSec .imgWrp {
  max-width: 500px;
}

.editClient .react-tel-input input[name="phone"] {
  max-width: 100%;
}

.priority-report .priority-table tbody tr td.medium-priority {
  background-color: #fcf8e3;
}

.priority-report .priority-table tbody tr td.high-priority {
  background-color: #f2dede;
}

.priority-report .priority-table tbody tr:hover td.medium-priority {
  background-color: #fbf4cf;
}

.priority-report .priority-table tbody tr:hover td.high-priority {
  background-color: #f0c9c9;
}

.priority-report .priority-table tbody tr td.Moderate-category {
  background-color: #fcf8e3;
}

.priority-report .priority-table tbody tr td.Severe-category {
  background-color: #f2dede;
}

.priority-report .priority-table tbody tr:hover td.Moderate-category {
  background-color: #fbf4cf;
}

.priority-report .priority-table tbody tr:hover td.Severe-category {
  background-color: #f0c9c9;
}

ul.symptom-totals {
  columns: 2;
}

.packages-accordion .accordion-button {
  display: flex;
  justify-content: space-between;
}

.packages-accordion .accordion-button:after {
  display: none;
}

.password-eye-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 15px;
  padding-right: 10px;
  font-size: 1rem;
}

.CalenderWrpper .calendar-event {
  padding: 5px 10px;
}

.CalenderWrpper .calendar-event.block {
  background-color: rgb(255, 255, 113);
  color: #000;
  height: 100%;
  padding: 5px;
}

.priority-report .plus-icon g,
.priority-report .plus-icon path {
  fill: #000;
}

.priority-report .priority-table tr td,
.recommendation-report .table tr td,
.recommendation-report .table tr th,
.priority-table tr td,
.food-mood-table tr td {
  white-space: normal;
  word-wrap: break-word;
}

.single-tag .delete-icon {
  width: 10px;
}

.single-tag .delete-icon path {
  stroke: #fff;
  fill: #fff;
}

.client-priorities-form .invalid-feedback {
  display: block;
}

.rbc-calendar .blocked-day {
  background-color: #ddd;
}

.rbc-calendar .rbc-day-bg {
  cursor: pointer;
}

.symptom-burden-graph .naq1-chart-container,
.data-analysis .naq1-chart-container {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.symptom-burden-graph ul.naq1-symptom-totals {
  columns: 3;
}

.tooltip-trigger i {
  color: #333;
}

.recommendation-table table tr td {
  white-space: normal !important;
  word-wrap: break-word;
}

.priority-table .add-icon,
.priority-table .edit-icon {
  fill: #08415c;
}

.priority-table .add-icon path,
.priority-table .edit-icon path {
  fill: #08415c;
}

.zoomEffect {
  animation: zoom 2s alternate;
  /* Apply the zoom animation */
  animation-iteration-count: 1;
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
    /* Initial scale */
  }

  100% {
    transform: scale(1);
    /* Final scale */
  }
}

@media (max-width: 992px) {
  .ProgressBarBox {
    order: -1;
    position: unset !important;
  }
}

@media (max-width: 1400px) {
  body {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  section.paymentSubs:after {
    width: 100%;
  }
}

@media (max-width: 575px) {
  header .commonBtn {
    font-size: 0;
  }

  .DocFormBox {
    flex-wrap: wrap;
  }

  header .commonBtn span.icn {
    margin-right: 0 !important;
  }

  header.siteHeader .pagetitle {
    font-size: 18px;
  }

  .commonContent h3 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  ul.halfDividedList li {
    flex-wrap: wrap;
  }

  .paginationWrpper {
    text-align: center;
  }

  .cstmInputCheckWrp {
    top: -1px;
  }

  ul.halfDividedList li>span {
    width: 100% !important;
  }
}

/* end css */

.gap-10.FormbtnWrpper {
  gap: 0 !important;
}

.gap-10.FormbtnWrpper button {
  margin-bottom: 4px;
}

.gap-10.FormbtnWrpper>button:not(:last-child) {
  margin-right: 6px;
}

.gap-10.FormbtnWrpper>a:not(:last-child) {
  margin-right: 6px;
}

.ActivityList li.event a {
  color: currentColor !important;
}

.todo-question .invalid-feedback {
  display: block;
}

.disclaimer.ck-editor .ck-editor .ck-editor__main .ck-content {
  height: 250px;
}

.answer-score {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.answer-score li {
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

.answer-score li.current-answer {
  color: #08415c;
  border: 1px solid #08415c;
  border-radius: 50%;
}

.severity-notes-table tr td:nth-child(1) {
  text-align: right;
}

.severity-notes-table tr td:nth-child(2) {
  padding-left: 20px;
}

.severity-notes td:has(table:empty) {
  display: none;
}

.recommendation-table .sort-btn {
  /* font-size: 20px; */
  padding: 5px;
  margin-bottom: 5px;
  min-width: 80px;
}

.recommendation-table .sort-btn:disabled {
  border: none;
  opacity: 0.3;
}

.pdf-download-btn a {
  color: #fff !important;
}

.download-pdf-dropdown .dropdown-item:active {
  background: #fff !important;
}

.wellness-chart-container {
  /* display: flex; */
  align-items: center;
  height: 400px;
}

.custom-calendar-strip {
  display: flex;
}

.custom-calendar-strip .week-day-btn {
  /* border: 1px solid #ddd; */
  margin: 5px;
  padding: 5px 10px;
}

.custom-calendar-strip .selected-date {
  background-color: #ffe0b8;
}

.custom-calendar-strip .week-btn-container {
  display: flex;
  align-items: center;
}

.custom-calendar-strip .week-control-btn {
  padding: unset;
  padding: 10px;
  display: flex;
  border: 2px solid #999;
  border-radius: 50%;
}

.fm-note-fields .color-swatch ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10;
  padding: 0 5px;
}

.fm-note-fields .input-group .input-group-text {
  padding: 0;
}

.fm-note-fields .input-group .input-group-text,
.fm-note-fields .input-group .input-group-text .color-btn,
.fm-note-fields .input-group .input-group-text .remove-btn {
  border-radius: 0;
}

.fm-data-table td {
  vertical-align: top !important;
  position: relative !important;
}

.fm-data-table .fm-data-cell {
  position: relative;
  vertical-align: top;
}

.my-log-list .feeling-card {
  background-color: #eee;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  border-left: 4px solid #000;
}

.my-log-list .feeling-card.feeling-great {
  border-left-color: #7fbf7f;
}

.my-log-list .feeling-card.feeling-good {
  border-left-color: #a5d2a5;
}

.my-log-list .feeling-card.feeling-so_so {
  border-left-color: #bfbfbf;
}

.my-log-list .feeling-card.feeling-bad {
  border-left-color: #fea5a5;
}

.my-log-list .feeling-card.feeling-horrible {
  border-left-color: #fe7f7f;
}

.highlight {
  color: #c7254e !important;
  background-color: #f9f2f4 !important;
  border-radius: 4px !important;
}

.password-strength {
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.password-strength .strength {
  font-weight: bold;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker .rc-time-picker-input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #2d3748;
  font-size: 14px;
}

.gettingStarted .step-link .green-check {
  display: none;
}

.gettingStarted .step-link .green-check path {
  fill: green;
}

.gettingStarted .step-link.completed .green-check {
  display: block;
}

.gettingStarted .step-link.completed {
  display: block;
  text-decoration: line-through;
}

.gettingStarted .step-link.completed:hover {
  text-decoration: line-through;
}

.client-phone .react-tel-input {
  font-size: inherit;
}

.client-phone .react-tel-input .flag-dropdown {
  display: none;
}

.client-phone .react-tel-input input {
  padding: 0;
  border: none;
  width: auto;
  height: auto;
  pointer-events: none;
  border-radius: 0;
  font-size: inherit;
}

.loader-overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Black background with opacity */
  z-index: 9999;
  /* Specify a stack order in case you're using a different order for other elements */
  /* cursor: pointer; Add a pointer on hover */
}

.ToDoPopup .form-disabled .cstmInputCheck {
  border: 1px solid #dfdfdf;
  background: #ededed;
}

.ToDoPopup .form-disabled .form-name {
  color: rgb(33 37 41 / 69%) !important;
}

.ToDoPopup .form-disabled .disabled-note {
  font-style: italic;
}

.packages-link.disabled {
  pointer-events: all !important;
}

/* CSS for React OTP Input */
.otp-container {
  display: flex;
  justify-content: space-between;
}

.otp-container .otp-input-field {
  width: 50px !important;
  /* Adjust width as needed */
  height: 50px !important;
  /* Adjust height as needed */
  font-size: 20px;
  /* Adjust font size as needed */
  text-align: center;
  border: 2px solid #ccc;
  /* Adjust border properties as needed */
  border-radius: 5px;
  /* Adjust border radius as needed */
}

.otp-container .otp-separator {
  margin: 0 5px;
  /* Adjust margin as needed */
  font-size: 20px;
  /* Adjust font size as needed */
}

.todo-question .cstmInputCheckWrp:has(input:focus-visible)+p {
  border: 1px solid #747474 !important;
}

.todo-question .question-help {
  font-size: 1.1em;
  margin: 0;
}

.rbc-calendar {
  height: 550px !important;
}

.CalenderWrpper .rbc-calendar .rbc-month-view .rbc-event {
  height: 30px !important;
}

.severity-notes .severity-notes-table tr td {
  vertical-align: top;
  border-bottom: 1px solid #eee;
  padding-top: 5px;
  /* padding-bottom: 5px; */
}

.RecommReportQueAnsPop .table.suggestions-que-ans tr td {
  white-space: normal;
  word-wrap: break-word;
}

.download-pdf-dropdown .dropdown-item {
  padding: 0;
}

.download-pdf-dropdown .dropdown-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}

.download-pdf-btn a {
  color: #fff !important;
}

.todo-form .todo-question .CstmScale ul {
  overflow: auto;
}

.naqv2-reports ul.symptom-totals .child-row {
  padding-left: 10px;
}

.subscription .PricingCard .CardBody {
  min-height: 325px;
}

.subscription-info .PricingCard {
  box-shadow: 5px 5px 10px 2px #c0c0c0;
}

.subscription-info .commonCardBox:not(.current-plan) {
  border-color: var(--bs-button);
}

.subscription-inactive-modal .btn-close {
  display: none;
}

.page-size-dropdown .dropdown-menu {
  min-width: unset;
}

.is-multiline {
  white-space: pre-wrap;
}

.subscription .PricingCard .subscribe-btn {
  position: absolute;
  bottom: 20px;
  width: 80% !important;
  left: 10%;
}

.maintenance-page {
  /* background-image: url("../Images/maintenance-new.png"); */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

.maintenance-page p {
  font-size: 16px;
  font-weight: bold;
}

.naq2-barchart .cstmBars .danger-sign {
  color: #d9534f;
  position: absolute;
  padding-left: 25px;
  visibility: visible;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.main-clr {
  color: #08415c;
}

.my-address-fields label {
  font-size: 12px;
}

.my-form-preview {
  margin: 3px 0px 5px 0px;
}

.previewFormField {
  width: 15px;
  height: 15px;
}


/* .react-time-picker .react-time-picker__wrapper {
  border: none;
} */

/*
.react-time-picker .react-time-picker__inputGroup .react-time-picker__inputGroup__input {
  border: 1px solid grey;
  padding: 0px 10px;
}

.react-time-picker .react-time-picker__inputGroup .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
  padding-left: 0;
}

.react-time-picker .react-time-picker__inputGroup__divider, .react-time-picker__inputGroup__leadingZero {
  margin-left: 5px;
}*/

/* .react-time-picker__inputGroup__leadingZero {
  border: 1px solid grey !important;
  border-right: none !important;
}

.react-time-picker__inputGroup__minute {
  border: 1px solid grey !important;
}

.react-time-picker__inputGroup__amPm {
  border: 1px solid grey;
  margin-left: 10px;

} */

.react-time-picker .react-time-picker__clear-button {
  display: none;
}

.nta-banner {
  /* position: fixed; */
  width: 100%;
  z-index: 999999;
  background: #fff;
  padding: 5px;
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #333;
}

.nta-banner p {
  margin: 0 !important;
}

.completed-forms .react-datepicker-wrapper .react-datepicker__input-container {
  display: flex;
  align-items: center;
}

.completed-forms .react-datepicker-wrapper .react-datepicker__input-container input {
  padding: 8px 10px 8px 25px;
  height: auto;
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .gap-10 {
    display: grid !important;
    grid-gap: 10px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .gap-10 {
    display: grid !important;
    grid-gap: 10px;
  }
}

/* end */

