button[type="reset"]{
    color: var(--bs-button);
}
button[type="reset"]:hover{
    color: var(--bs-buttonHover) !important;
}
@media (max-width: 992px) {
    .filterWrpper {
        position: fixed;
        top: 0;
        z-index: 9999;
        background: #fff;
        height: 100vh;
        max-width: 300px;
        margin: 0 !important;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: 0.4s;
        padding: 0;
          .closeBtn {
            height: 30px;
            width: 30px;
        }
        .FilterWrp{
            height: calc(100% - 55px);
            overflow-y: scroll;
            padding: 10px 15px;
        }
    }
    .filterWrpper:not(.active){
        transform: translateX(-110%);
    }
}