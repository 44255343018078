.RecentSidebar {
  background: #fff;
  // height: calc(100vh - 59px);
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  width: 270px;
  flex-shrink: 0;
  top: 59px;
  transition: 0.4s;
  .ActivityList {    
    li:first-child {
      background: #fffef7;
    }
    .icn {
      flex-shrink: 0;
      height: 28px;
      width: 28px;
      background: #f4694b;
      z-index: 9;
    }
    .content {
      h6 {
        font-size: 14px;
        line-height: 20px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
      }
       i {
        font-size: 11px;
        line-height: 20px;
      }
    }
  }
  .ActivityList:after {
    position: absolute;
    content: "";
    left: 30px;
    top: 16px;
    width: 1px;
    height: calc(100% - 15px);
    border: 1px dashed #ddd;
    z-index: 0;
  }
  // .Inner {
  //   overflow-y: scroll;
  //   height: calc(100% - 55px);
  // }
  .top {
    h3 {
      font-size: 18px;
    }
  }
}

@media (max-width: 1200px){
    .RecentSidebar {
        // position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        // padding-top: 100px !important;
        z-index: 9999;
        .closeBtn {
            display: flex !important;
            height: 35px;
            width: 35px;
        }
        .top{
            text-align: center;
        }
    }
    .RecentSidebar:not(.active) {
        transform: translateX(100%);
    }
}