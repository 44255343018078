.GuideSidebar {
  .accordion {
    height: calc(100% - 40px);
    overflow-y: scroll;
    .LinksWrpper {
      height: auto !important;
      .accordion-button{
        justify-content: start !important;
        padding: 8px !important;
        span.icn{
          width: unset !important;
          svg {
            height: 20px !important;
            width: 20px !important;
          }
        }
      }
      span.icn svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
