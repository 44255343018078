.RightSidebar{
    
    background: #fff;
    height: calc(100vh - 59px);
    width: 300px;
    top: 59px;
    flex-shrink: 0;
    transition: 0.4s;
    font-size: 12px;
    border-left: 1px solid #ddd;
    // word-break: break-all;
    .Inner {
       overflow-y: scroll;
       height: calc(100%);
}
}
@media (max-width: 1200px){
    .RightSidebar {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        // padding-top: 100px !important;
        z-index: 9999;
        .closeBtn {
            display: flex !important;
            height: 35px;
            width: 35px;
        }
        .Inner{
       height: calc(100% - 55px);
        }
    }
    .RightSidebar:not(.active) {
        transform: translateX(100%);
    }
}